<script lang="ts">
// import formValidationInit from '~/packages/datakode/nuxt-datakode-utils/utils/validations/formValidationInit.vue'

export default {
    // extends: formValidationInit,
    props: {
        data: { type: Object, required: true },
        translationName: { type: String, required: true },
        validationRules: { type: Object, required: true },
        validation: { type: Object, required: true },
        saving: { type: Boolean, required: false },
    },
    emits: ['save', 'updateData', 'closeModal'],

    methods: {
    },
}
</script>

<template>
    <v-btn
        v-if="$permissionCheck(`myProfile_update`)"
        prepend-icon="mdi-square-edit-outline"
        color="primary"
        @click.prevent="$refs.modalParent?.showModal()"
    >
        Modifier
    </v-btn>
    <ModalMiddle
        ref="modalParent"
        :max-width="800"
        save-text="Modifier mon compte"
        custom-button-color="primary"
        :custom-button-block="true"
        :is-form="true"
        :saving
        title-modal="Modifier mon compte"
        :data="data"
        @save="$emit('save')"
        @close-modal="$emit('closeModal')"
    >
        <template #content>
            <v-form
                fast-fail
                @submit.prevent
            >
                <FormUsersSectionsGlobalInfo
                    :data="data" :translation-name="translationName"
                    :is-form="true"
                    :validation="validation"
                    :validation-rules="validationRules"
                    @update-data="$emit('updateData', $event)"
                />
                <FormUsersSectionsAccessApp :data="data" :translation-name="translationName" :is-form="true" @updateData="$emit('updateData', $event)" />
                <FormUsersSectionsResetPasswordInputs
                    v-if="$permissionCheck('user_canResetPassword')"
                    :data="data"
                    :translation-name="translationName"
                    @update-data="$emit('updateData', $event)"
                />
            </v-form>
        </template>
    </ModalMiddle>
</template>
