<script lang="ts">
export default {
    name: 'History',
    props: {
        data: { type: Object, required: true },
        translationName: { type: String, required: true },
    },
}
</script>

<template>
    <FormSection v-if="data?.credentials" title="Historique" with-borders-color="primary">
        <FormDatetime
            :enable-time-picker="true"
            data-key="credentials.created_at"
            :data="data"
            :translation-name="translationName"
        />
        <FormDatetime
            :enable-time-picker="true"
            data-key="credentials.updated_at"
            :data="data"
            :translation-name="translationName"
        />

        <template v-if="data?.credentials?.updated_by">
            <FormInput
                data-key="credentials.updated_by.name"
                :data="data"
                :translation-name="translationName"
            />
        </template>
    </FormSection>
</template>
