<script lang="ts">
import formValidationInit from '~/packages/datakode/nuxt-datakode-utils/utils/validations/formValidationInit.vue'

export default {
    name: 'ProfilIndex',
    extends: formValidationInit,
    props: {
        loading: { type: Boolean, required: false },
        saving: { type: Boolean, required: false },
    },
    emits: ['save', 'closeModal'],
}
</script>

<template>
    <div>
        <ModalsLoader :loading="loading || false" />
        <Profil
            ref="profil"
            v-if="!loading && data && $permissionCheck('myProfile_show')"
            :data="data"
            :saving
            :validation-rules="$permissionCheck('myProfile_update') ? validationRules : {}"
            :validation="$permissionCheck('myProfile_update') ? v$ : {}"
            :translation-name="translationName"
            @save="$emit('save')"
            @close-modal="$emit('closeModal')"
            @update-data="updateData($event)"
        />
    </div>
</template>
