<script lang="ts">
export default {
    name: 'AccessRight',
    props: {
        data: { type: Object, required: true },
        translationName: { type: String, required: true },
        isForm: { type: Boolean, required: false },
    },
    methods : {
        deleteAccessRequest (item: { id: any; }) {
            this.$api.setRequest({
                url: `access-requests/${item.id}`,
                method: 'DELETE',
                translation: `${this.translationName}.new_access_requests`,
            }).then(() => {
                const requestFiltered = this.data.new_access_requests.filter( (request: { id: any; }) => request.id !== item.id)
                this.$emit('updateData', { new_access_requests : requestFiltered })
            })
        },
        deleteAppAccess (item) {
            this.$api.setRequest({
                url: `application-roles/${item.id}`,
                method: 'DELETE',
                translation: `${this.translationName}.application_roles`,
            }).then(() => {
                const appsFiltered = this.data.application_roles.filter( (role: { id: any; }) => role.id !== item.id)
                this.$emit('updateData', { application_roles : appsFiltered })
            })
        },
    }
}
</script>

<template>
    <FormSection title="Mes applications" with-borders-color="primary" v-if="data && data.role !== 'admin'">
        <div v-for="(item, i) in data.application_roles" :key="item.k" class="mb-2">
            <v-label>
                {{  item.application.title }}
            </v-label>
            <!-- AFFICHAGE ROLE PAR APP SUR LA VIEW -->
            <template v-if="!isForm">
                <br>
                <v-chip :color="$getColor(`utilisateurs.application_roles.role.${item.role}`)">
                    {{ $translate(`utilisateurs.application_roles.role.${item.role}`) }}
                </v-chip>
            </template>

            <!-- AFFICHAGE ROLE PAR APP SUR LE FORM -->
             <template v-else>
                <div class="text-caption text-medium-emphasis">Une nouvelle demande sera nécessaire pour y accéder.</div>
                <!-- change permission todo -->
                <ListDeleteConfirmation
                    v-if="item && $permissionCheck(`accessRequest_destroy`)" 
                    :item="item.application"
                    translation-name="utilisateurs.application_roles"
                    button="<v-btn variant='text' class='px-1' color='danger' size='small' prepend-icon='mdi-logout'>Quitter l'application</v-btn>"
                    @delete-data="deleteAppAccess(item)"
                />

             </template>
             <v-divider class="mt-2" v-if="(i+1 !== data.new_access_requests.length) || data.new_access_requests.length" />
        </div>

        <div v-for="(item,i) in data.new_access_requests" :key="item.k" class="mb-2">

            <!-- AFFICHAGE ACCESS REQUEST SUR LA VIEW -->
            <template v-if="!isForm">
                <v-label>
                    {{  item.application.title  }}
                </v-label> 
                <div>
                    <v-chip color="info" class="mr-2">En attente</v-chip>
                    <v-tooltip :text="item.text">
                        <template v-slot:activator="{ props }">
                            <div class="text-truncate" v-bind="props">{{ item.text }}</div>
                        </template>
                    </v-tooltip>
                </div>
            </template>

            <!-- AFFICHAGE ACCESS REQUEST SUR LE FORM -->
            <template v-else>
                <v-label>Motivations pour accéder à {{ item.application.title }}</v-label>
                <v-textarea disabled v-model="item.text" variant="outlined"/>
                <ListDeleteConfirmation
                    v-if="item && $permissionCheck(`accessRequest_destroy`)"
                    :item="item.application"
                    translation-name="utilisateurs.new_access_requests"
                    button="<v-btn variant='text' class='px-1' color='danger' size='small' prepend-icon='mdi-close'>Annuler ma demande</v-btn>"
                    @delete-data="deleteAccessRequest(item)"
                />
                
            </template>
            <v-divider class="mt-2" v-if="i+1 !== data.new_access_requests.length" />
        </div>
    </FormSection>
</template>
