<script lang="ts">
import { useUserStore  } from '~/store'

export default {
    name: 'MenuUserProfile',
    props: {
        data: { type: Object, required: true },
        translationName: { type: String, required: true },
        saving: { type: Boolean, required: false },
        validationRules: { type: Object, required: true },
        validation: { type: Object, required: true },
    },
    emits: ['save', 'updateData', 'closeModal'],
    setup() {
        const access_token = useCookie<string>('access_token')
        const userStore = useUserStore()

        return {
            access_token,
            userStore,
        }
    },
    data() {
        return {
            currentData : {...this.data }
        }
    },
    methods: {
        deleteAccount() {
            this.$api.setRequest({
                url: `my-profile`,
                method: 'DELETE',
                translation: this.$attrs['translation-name'],
            }).then(async () => {
                await this.$api.setRequest({
                    url: 'logout',
                    method: 'POST',
                })
                this.access_token = null
                await this.userStore.clearUser()
                this.$router.push('/')
            })
        },
    },
}
</script>

<template>
    <v-row v-if="data">
        <v-col cols="12">
            <ProfilForm :saving ref="profilForm" :data="data" :validation-rules="validationRules" :translation-name="translationName" :validation="validation"
                @save="$emit('save');"
                @close-modal="$emit('closeModal')"
                @update-data="$emit('updateData', $event)"
            />
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
            <FormUsersSectionsGlobalInfo :data="currentData" :translation-name="translationName" :is-form="false">
                <template #inputs v-if="currentData.role !== 'admin'">
                    <ListDeleteConfirmation
                        v-if="currentData && $permissionCheck(`myProfile_destroy`)"
                        :item="currentData"
                        :translation-name="translationName"
                        button="<v-btn color='danger' variant='text' class='px-1' prepend-icon='mdi-delete'>Supprimer mon compte</v-btn>"
                        @delete-data="deleteAccount"
                    />
                </template>
            </FormUsersSectionsGlobalInfo>
        </v-col>
        <v-col cols="12" md="6">
            <FormUsersSectionsAccessApp :data="currentData" :translation-name="translationName" />
            <FormUsersSectionsHistory :data="currentData" :translation-name="translationName" />
        </v-col>
    </v-row>
</template>
